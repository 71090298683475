<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <label for="my-first-home"> Home </label>
        <InputFormContainer
          meta-key="my-first-home"
          @save="saveChanges"
          :height="100"
        />
      </div>
      <div class="col-md-6">
        <label for="my-first-pet"> Pet </label>
        <InputFormContainer
          meta-key="my-first-pet"
          @save="saveChanges"
          :height="100"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="my-first-home"> Best Friend </label>
        <InputFormContainer
          meta-key="my-first-home"
          @save="saveChanges"
          :height="100"
        />
      </div>
      <div class="col-md-6">
        <label for="my-first-pet"> Kiss </label>
        <InputFormContainer
          meta-key="my-first-pet"
          @save="saveChanges"
          :height="100"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <label for="my-first-home"> Car </label>
        <InputFormContainer
          meta-key="my-first-home"
          @save="saveChanges"
          :height="100"
        />
      </div>
      <div class="col-md-6">
        <label for="my-first-pet"> Boyfriend or Girlfriend </label>
        <InputFormContainer
          meta-key="my-first-pet"
          @save="saveChanges"
          :height="100"
        />
      </div>
    </div>

    <h4 class="mt-5">Add Your Own Firsts</h4>

    <div class="row">
      <div class="col-md-6">
        <InputFormContainer
          type="input"
          meta-key="my-first-custom-1-name"
          @save="saveChanges"
          :height="100"
          placeholder="Enter your own prompt"
        />
        <InputFormContainer
          meta-key="my-first-custom-1-value"
          @save="saveChanges"
          :height="100"
          class="mt-2"
        />
      </div>
      <div class="col-md-6">
        <InputFormContainer
          type="input"
          meta-key="my-first-custom-2-name"
          @save="saveChanges"
          :height="100"
          placeholder="Enter your own prompt"
        />
        <InputFormContainer
          meta-key="my-first-custom-2-value"
          @save="saveChanges"
          :height="100"
          class="mt-2"
        />
      </div>
    </div>
    <div class="row mt-3 border-top pt-3">
      <div class="col-md-6">
        <InputFormContainer
          type="input"
          meta-key="my-first-custom-3-name"
          @save="saveChanges"
          :height="100"
          placeholder="Enter your own prompt"
        />
        <InputFormContainer
          meta-key="my-first-custom-3-value"
          @save="saveChanges"
          :height="100"
          class="mt-2"
        />
      </div>
      <div class="col-md-6">
        <InputFormContainer
          type="input"
          meta-key="my-first-custom-4-name"
          @save="saveChanges"
          :height="100"
          placeholder="Enter your own prompt"
        />
        <InputFormContainer
          meta-key="my-first-custom-4-value"
          @save="saveChanges"
          :height="100"
          class="mt-2"
        />
      </div>
    </div>
    <div class="row mt-3 border-top pt-3">
      <div class="col-md-6">
        <InputFormContainer
          type="input"
          meta-key="my-first-custom-5-name"
          @save="saveChanges"
          :height="100"
          placeholder="Enter your own prompt"
        />
        <InputFormContainer
          meta-key="my-first-custom-5-value"
          @save="saveChanges"
          :height="100"
          class="mt-2"
        />
      </div>
      <div class="col-md-6">
        <InputFormContainer
          type="input"
          meta-key="my-first-custom-6-name"
          @save="saveChanges"
          :height="100"
          placeholder="Enter your own prompt"
        />
        <InputFormContainer
          meta-key="my-first-custom-6-value"
          @save="saveChanges"
          :height="100"
          class="mt-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputFormContainer from "../InputFormContainer.vue";
export default {
  components: {
    InputFormContainer,
  },
  data() {
    return {
      content: "",
    };
  },
  methods: {
    saveChanges({ metaKey, content }) {
      switch (metaKey) {
        default:
          this.$store.dispatch("updateUserMeta", [
            metaKey,
            content,
            ({ message }) => {
              this.$toast.open({
                message,
                position: "bottom-left",
                type: "success",
              });
            },
          ]);
          break;
      }
    },
  },
};
</script>

<style scoped>
div .input-form {
  height: 500px;
}
</style>